export const getCategory = (paramIds, items) => {
  const idList = [];
  const names = [];

  if (Array.isArray(items) === false) return {idList, names};

  for (const item of items) {
    if (item.categoryIdsInChannel.join(',') === paramIds) {
      idList.push(item.categoryId)
      names.push(item.categoryName)
    }

    const deep = getCategory(paramIds, item.subcategories);
    if (deep.idList.length) {
      idList.push(item.categoryId)
      names.push(item.categoryName)
      idList.push(...deep.idList);
      names.push(...deep.names);
      break;
    }
  }

  return { idList, names};
};
