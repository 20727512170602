/**
 * 
 * @param {*} str: url search query
 * @returns {object} params
 * @example 
 * getParams('?keyword=%E9%99%90%E9%87%8F%E4%BC%B4%E6%89%8B%E7%A6%AE&id=1,2,3')
 * ->
 * {
      "keyword": [
        "%E9%99%90%E9%87%8F%E4%BC%B4%E6%89%8B%E7%A6%AE"
      ],
      "id": [
        "1,2,3"
      ]
    }
 */
export const getParams = (str) => {
  let queryString = str || window.location.search || '';
  let query = [];
  const params = {};

  // Replace the '?'
  queryString = queryString.replace(/.*?\?/, '');

  if (queryString.length) {
    query = queryString.split('&');
    for (let num in query) {
      const key = query[num].split('=')[0];
      if (!key.length) {
        continue;
      }
      if (typeof params[key] === 'undefined') {
        params[key] = [];
      }
      params[key].push(query[num].split('=')[1]);
    }
  }
  return params;
};
