import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, suspense, decryptFunction } from 'utils';

// TODO: query fetch 應該可以抽出去

// 列表
export const fetchReceiverList = createAsyncThunk(
  'receiver/fetchReceiverList',
  async (_, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.receiverList();

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData.data.list;
  }
);

// 新增
export const createReceiver = createAsyncThunk(
  'receiver/createReceiver',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let queryStatus = {};
    let retry = 0;

    const {
      identificationNumber,
      firstName,
      mobileNumber,
      cityCode,
      zipCode,
      zipCodeNo,
      address,
      memo,
      preference,
    } = reqData;

    // 新增
    do {
      resData = await Service.receiverCreate({
        consignee: {
          identificationType: 1,
          identificationNumber,
          firstName,
          lastName: '',
          gender: 0,
          mobileCountryCode: '886',
          mobileNumber,
        },
        deliveryAddress: {
          languageCode: 2,
          countryId: 1,
          province: '台灣',
          cityCode,
          zipCode,
          zipCodeNo,
          address,
          memo,
        },
        preference,
      });

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    // 新增完的查詢
    do {
      queryStatus = await Service.receiverQuery(resData.data.queueIndex);

      if (queryStatus.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue(queryStatus.errMsg);
    }

    return queryStatus;
  }
);

// 詳細資訊(編輯頁)
export const fetchReceiverDetail = createAsyncThunk(
  'receiver/fetchReceiverDetail',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.receiverDetail(reqData);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue({
        type: 'detail',
        message: resData.errMsg,
      });
    }

    // 解密處理
    const tempResData = {
      consignee: {},
      deliveryAddress: {},
      id: resData.data.id,
      preference: resData.data.preference,
    };

    const decodeData = (value, key) =>
      Object.entries(value).map(([blockKey, item]) => {
        tempResData[key][blockKey] = decryptFunction(item);
      });
    decodeData(resData.data.consignee, 'consignee');
    decodeData(resData.data.deliveryAddress, 'deliveryAddress');

    return tempResData;
  }
);

// 編輯
export const updateReceiver = createAsyncThunk(
  'receiver/updateReceiver',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let queryStatus = {};
    let retry = 0;

    const {
      id,
      identificationNumber,
      firstName,
      mobileNumber,
      cityCode,
      zipCode,
      zipCodeNo,
      address,
      memo,
      preference,
    } = reqData;

    const params = {
      id,
      data: {
        consignee: {
          identificationType: 1,
          identificationNumber,
          firstName,
          lastName: '',
          gender: 0,
          mobileCountryCode: '886',
          mobileNumber,
        },
        deliveryAddress: {
          languageCode: 2,
          countryId: 1,
          province: '台灣',
          cityCode,
          zipCode,
          zipCodeNo,
          address,
          memo,
        },
        preference,
      },
    };

    // 編輯
    do {
      resData = await Service.receiverUpdate(params);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue({
        type: 'update',
        message: resData.errMsg,
      });
    }

    // 編輯完的查詢
    do {
      queryStatus = await Service.receiverQuery(resData.data.queueIndex);

      if (queryStatus.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue({
        type: 'query',
        message: queryStatus.errMsg,
      });
    }

    return queryStatus;
  }
);

// 刪除
export const deleteReceiver = createAsyncThunk(
  'receiver/deleteReceiver',
  async (reqData, { rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.receiverDelete(reqData);

      if (resData.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    return resData;
  }
);

const initialState = {
  list: [],
  detail: {},
};

const receiverSlice = createSlice({
  name: 'receiver',
  initialState,
  reducers: {
    resetReceiver: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceiverList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReceiverList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = payload;
      })
      .addCase(fetchReceiverList.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(fetchReceiverDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReceiverDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.detail = payload;
      })
      .addCase(fetchReceiverDetail.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorType = payload.type;
        state.message = payload.message;
      })
      .addCase(createReceiver.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      })
      .addCase(updateReceiver.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorType = payload.type;
        state.message = payload.message;
      })
      .addCase(deleteReceiver.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      });
  },
});

export const { resetReceiver } = receiverSlice.actions;
export default receiverSlice.reducer;
