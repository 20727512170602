import tw, { styled } from 'twin.macro';

const flexConfig = tw`
  flex
  items-center
  absolute
  z-20
`;

const itemConfig = tw`
  max-w-[96px]
  max-h-[124px]
  bg-white
  rounded-lg
  text-sm
  text-grayScale-600
  flex-col
  p-5
  top-1/2
  left-1/2
  -translate-x-1/2
  -translate-y-1/2
`;

export const StyledLoadingLayout = styled.div(() => [
  flexConfig,
  itemConfig,
  tw`-mt-11`, // header 高度固定 44px
]);

export const StyledLoadingWithMask = styled.div(({ open }) => [
  tw`
    w-screen
    h-screen
    bg-white/60
    fixed
    left-0
    right-0
    top-0
    bottom-0
    z-50
    invisible
    opacity-0
    transition-all
    duration-100
  `,
  open && tw`visible opacity-100`,
]);

export const StyledContainer = styled.div(() => [flexConfig, itemConfig]);

export const StyledText = styled.p`
  ${tw`mt-4`}
`;

// 小 loading 給區塊用，需由外層給 relative
export const StyledLoadingSmallLayout = styled.div(() => [
  flexConfig,
  tw`
    w-full
    h-full
    bg-white/50
    justify-center
    gap-3
  `,
]);

export const StyledLoadingScrollWrap = styled.div`
  ${tw`flex place-content-center col-span-2 gap-2 text-grayScale-600 py-4`}
`;
