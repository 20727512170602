import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Service } from 'utils';

const initialState = {
  loading: null,
  error: null,
  channelList: [],
  mainCategory: [],
  subCategory: {},
  channelIds: null,
  activeId: null,
};

export const fetchMenu = createAsyncThunk(
  'menu/fetchMenu',
  async (_, { getState }) => {
    const state = getState();
    const channelKey = state.common.menuChannelKey;

    const productList = await Service.menu();
    const channelList = productList.map((item) => item.channelKey);
    const currentChannel = productList.find(
      (item) => item.channelKey === channelKey
    );

    return { channelList, currentChannel };
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    resetMenu: (state) => {
      state.mainCategory = initialState.mainCategory;
      state.subCategory = initialState.subCategory;
      state.loading = initialState.loading;
      state.channelIds = initialState.channelIds;
      state.error = initialState.error;
    },
    setActiveId: (state, { payload }) => {
      state.activeId = payload;
    },
    resetActiveId: (state) => {
      state.activeId = initialState.activeId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenu.fulfilled, (state, { payload }) => {
        state.channelList = payload.channelList;
        const currentCategories = payload.currentChannel?.categories;
        if (currentCategories) {
          let channelIds = [];
          state.subCategory = currentCategories.reduce((acc, curr) => {
            const key = curr.categoryId;
            acc = {
              ...acc,
              [key]: {
                name: curr.categoryName,
                items: curr.subcategories,
              },
            };
            const currIds = curr.subcategories.flatMap((obj) => {
              return obj.subcategories.flatMap((item) => item.categoryIdsInChannel);
            });
            channelIds = [...channelIds, ...currIds]
            return acc;
          }, {});
          state.mainCategory = currentCategories.map((obj) => {
            delete obj.subcategories;
            return obj;
          });
          state.channelIds = channelIds.filter((id) => id);
        }
        // 進入選單頁面，預設顯示
        if (!state.activeId) {
          state.activeId = currentCategories[0].categoryId;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMenu.rejected, (state, action) => {
        state.mainCategory = initialState.mainCategory;
        state.subCategory = initialState.subCategory;
        state.activeId = initialState.activeId;
        state.error = action.payload;
        state.channelIds = [];
        state.loading = false;
      });
  },
});
export const { resetMenu, setActiveId, resetActiveId } = menuSlice.actions;

export default menuSlice.reducer;
