import React from 'react';
import PropTypes from 'prop-types';
import { StyledButtonLayout } from './styled';

const Button = ({
  type = 'button',
  text,
  variant = 'primary',
  disabled = false,
  onClick,
  children,
  ...rest
}) => (
  <StyledButtonLayout
    type={type}
    variant={variant}
    disabled={disabled}
    {...(!disabled && { onClick })}
    {...rest}
  >
    {children ? children : <span>{text}</span>}
  </StyledButtonLayout>
);

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text']),
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default Button;
