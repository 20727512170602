import React from 'react';
import PropTypes from 'prop-types';
import { StyledBadgeLayout } from './styled';

// 圓角、色系由外層決定
const Badge = ({ text, ...rest }) => (
  <StyledBadgeLayout {...rest}>{text}</StyledBadgeLayout>
);

Badge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Badge;
