import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { Service, suspense, setCookieWithDomain } from 'utils';

// 取得 userId
export const fetchUserId = createAsyncThunk(
  'user/fetchUserId',
  async (_, { rejectWithValue }) => {
    let resData = 0;
    let retry = 0;

    do {
      resData = await Service.decodeToken();
      if (resData?.hasError) {
        break;
      }

      if (resData?.code === -5001) {
        await suspense();
        retry += 1;

        // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
        if (retry >= 15) break;
      }
    } while (resData?.code === -5001);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(resData.errMsg);
    }

    // 將 userId 寫入 cookie，當頁面重刷 state 會重置，因此從 cookie 取來塞預設值
    setCookieWithDomain('uid', resData.data.id);
    return resData.data.id;
  }
);

const initialState = {
  uid: Cookies.get('uid') ?? null,
};

const userInfoSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserId.fulfilled, (state, { payload }) => {
        state.uid = payload;
      })
      .addCase(fetchUserId.rejected, (state, { payload }) => {
        state.message = payload;
      });
  },
});

export default userInfoSlice.reducer;
