import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainerLayout } from './styled';

const Container = ({ children, ...rest }) => (
  <StyledContainerLayout {...rest}>{children}</StyledContainerLayout>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
