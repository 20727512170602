import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  StyledEmptyLayout,
  StyledEmptyContainer,
  StyledContentWrap,
  StyledTextWrap,
  StyledTitle,
  StyledContent,
  StyledButton,
} from './styled';

const Empty = ({
  type = 'common',
  title = '系統忙線中，請10分鐘後再試',
  content = null,
  onClick,
  buttonText = '返回',
}) => {
  const navigate = useNavigate();
  const defaultClick = () => {
    navigate('/');
  };

  const images = {
    common: '/images/empty_bibian.png',
    cart: '/images/empty_cart.svg',
    search: '/images/search_error.svg',
    orders_empty: '/images/orders_empty.svg',
    orders_error: '/images/orders_delivery_empty.svg',
  };

  return (
    <StyledEmptyLayout>
      <StyledEmptyContainer>
        <StyledContentWrap>
          <img src={images[type]} alt="empty-page" width="110" height="110" />

          <StyledTextWrap>
            <StyledTitle>{title}</StyledTitle>
            {content && <StyledContent>{content}</StyledContent>}
          </StyledTextWrap>
        </StyledContentWrap>
        {buttonText && (
          <StyledButton
            onClick={onClick || defaultClick}
            text={buttonText}
          ></StyledButton>
        )}
      </StyledEmptyContainer>
    </StyledEmptyLayout>
  );
};

Empty.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
  buttonText: PropTypes.string,
};

export default Empty;
