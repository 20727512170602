import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, encryptMD5, dayjs } from 'utils';
import Cookies from 'js-cookie';

const ez1Token = Cookies.get('ez1');

export const fetchMemberData = createAsyncThunk(
  'member/fetchMemberData',
  async (_, { dispatch, rejectWithValue }) => {
    let resData = {};

    try {
      const timestamp = dayjs().valueOf();
      const reqHeaders = {
        AccessKey: 'BibianMainApiUse',
        Timestamp: timestamp,
        Token: encryptMD5(
          `${process.env.REACT_APP_MEMBER_API_SECRET}${timestamp}`
        ),
      };

      resData = await Service.memberData(reqHeaders, ez1Token);

      // memberData API 錯誤
      if (resData?.hasError) {
        return rejectWithValue('memberData API 錯誤，系統異常');
      }

      if (resData?.code && resData?.code < 0) {
        return rejectWithValue(resData.errMsg);
      }

      return resData.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  data: {},
  loading: null,
  message: '',
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemberData.pending, (state) => {
        state.loading = true;
        state.message = '';
      })
      .addCase(fetchMemberData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(fetchMemberData.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
      });
  },
});

export default memberSlice.reducer;
