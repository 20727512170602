import tw, { styled } from 'twin.macro';

const variantConfig = {
  primary: tw`bg-primary`,
  secondary: tw`bg-secondary`,
  danger: tw`bg-red-600`,
  text: tw`bg-transparent`,
};

export const StyledButtonLayout = styled.button(({ variant, disabled }) => [
  tw`
    text-black/80
    font-medium
    border-0
    border-none
    rounded-[100px]
    px-14
    py-2
    outline-0
    cursor-pointer
  `,
  variant && variantConfig[variant],
  disabled && tw`text-white bg-grayScale-500 cursor-default`,
]);
