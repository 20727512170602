import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  Service,
  setCookieWithDomain,
  removeCookie,
  getParams,
  encryptMD5,
} from 'utils';
import Cookies from 'js-cookie';

const initialState = {
  familyId: null,
  bibianToken: null,
  status: '',
  message: '',
  redirectUrl: '',
};
export const fetchFamilyInfo = createAsyncThunk(
  'user/fetchFamilyInfo',
  async (_, thunkAPI) => {
    try {
      let trans = null;
      const params = getParams();
      trans = params?.Trans_info2?.[0];

      // 網址取不到密文時改取cookie
      if (!trans) {
        const cookieTrans = Cookies.get('Trans_info2');
        if (!cookieTrans) {
          return thunkAPI.rejectWithValue('無法正確取得登入密文');
        }
        trans = cookieTrans;
      }
      const timestamp = new Date().getTime();
      const reqData = {
        Trans_info2: trans,
        accessKey: 'familyMartBibian',
        timestamp,
        token: `${encryptMD5('familyMartBibian')}${timestamp}`,
        path: window?.location?.pathname || '/',
      };
      const reqArray = Object.entries(reqData).map(
        ([key, value]) => `${key}=${value}`
      );
      const reqParams = reqArray.join('&');
      const resData = await Service.familyInfo(reqParams);

      if (resData.code === -10002 || resData.code === -70005) {
        // 密文保留 24小時 * 30 天 效期
        setCookieWithDomain('Trans_info2', trans, 60 * 24 * 30);
        removeCookie('ez1');
        return {
          familyId: initialState.memberId,
          bibianToken: initialState.bibianToken,
          message: resData.message,
          redirectUrl: resData.data.redirectUrl,
          status: 'pending',
        };
      }
      if (resData.code === 1) {
        setCookieWithDomain('ez1', resData.data.ez1);
        setCookieWithDomain('Trans_info2', trans, 60 * 24 * 30);
        return {
          familyId: resData.data.memberId,
          bibianToken: resData.data.ez1,
          message: initialState.message,
          redirectUrl: initialState.redirectUrl,
          status: 'success',
        };
      }
      return thunkAPI.rejectWithValue(resData.message);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const familyInfoSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFamilyInfo.pending, (state) => {
        state.familyId = initialState.familyId;
        state.bibianToken = initialState.bibianToken;
        state.redirectUrl = initialState.redirectUrl;
        state.message = initialState.message;
        state.status = initialState.status;
      })
      .addCase(fetchFamilyInfo.fulfilled, (state, { payload }) => {
        state.familyId = payload.familyId;
        state.bibianToken = payload.bibianToken;
        state.redirectUrl = payload.redirectUrl;
        state.message = payload.message;
        state.status = payload.status;
      })
      .addCase(fetchFamilyInfo.rejected, (state, { payload }) => {
        removeCookie('ez1');
        state.familyId = initialState.familyId;
        state.bibianToken = initialState.bibianToken;
        state.message = payload;
        state.status = 'failed';
      });
  },
});

export default familyInfoSlice.reducer;
