import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Image from 'components/image';
import {
  StyledErrorLayout,
  StyledWrap,
  StyledActions,
  StyledButton,
} from './styled';

const Error = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // 回首頁
  const btnRedirectToHome = () => {
    navigate('/');
  };

  // 重新整理
  const btnReload = () => {
    navigate(0);
  };

  return (
    <StyledErrorLayout>
      <StyledWrap>
        <Image
          src="/images/empty_bibian.png"
          alt="error"
          width="110"
          height="110"
        />
        系統繁忙中...
        <StyledActions>
          {pathname !== '/' && (
            <StyledButton
              text="回首頁"
              variant="secondary"
              onClick={btnRedirectToHome}
            />
          )}

          <StyledButton text="重新整理" onClick={btnReload} />
        </StyledActions>
      </StyledWrap>
    </StyledErrorLayout>
  );
};

export default Error;
