import { constants } from 'utils';

const { orderDeliveryInfo, defaultImage } = constants;

/**
 * 複製到剪貼簿
 * @param {*} func: Function to set tip
 * @param {*} value: Copy value
 * @param {*} tip: About copy action Tip content
 */
export const handleCopy = async (func, value, tip) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(value);
    func(tip);
  } else {
    // FIX: http測試環境用, 上正式後可移除
    func(tip);
  }
};

/**
 * // TODO: 之後要再調整並統一業務邏輯(國內配送 type)
 * 全家包裹查詢
 * @param {*} name: The name to match delivery info
 * @returns {object}
 */
export const handleDeliveryInfo = (name) => {
  if (name.includes('新竹')) {
    return orderDeliveryInfo[0];
  }

  if (name.includes('黑貓')) {
    return orderDeliveryInfo[1];
  }

  if (name.includes('全家')) {
    return orderDeliveryInfo[2];
  }

  return {
    name,
    url: '',
  };
};

/**
 *
 * @param {*} eventName - GA event
 * @param {*} items: ecommerce content
 */
export const GAEcommerceEvent = (eventName, items) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: `${eventName}`,
    ecommerce: items,
  });
};

/**
 *
 * @param {*} e - image DOM event
 */
export const onImageError = (e) => {
  e.target.onerror = null;
  e.target.src = defaultImage;
};

/**
 *
 * @param {object} data : From home product - when data have id & key, use promotion route
 * @returns {string} url
 */
export const usePromotionUrl = (data) => {
  const blockKeys = [
    'salesRanking',
    'latestProducts',
    'highlyRatedJapanAmazonProducts',
  ];

  let url = data?.url ?? '/';

  if (data?.id && data?.key && blockKeys.includes(data.key)) {
    url = `/promotion/${data.key}?id=${data.id}`;
  }

  return url;
};
