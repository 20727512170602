import { createApi } from '@reduxjs/toolkit/query/react';
import { Service, formatFullTime } from 'utils';

const formatAsk = (payload) => {
  const empty = [];

  if (payload) {
    const res = payload.map((item) => ({
      orderId: item.order_id,
      time: formatFullTime(item.ask_time, 'slash'),
      image: item.file_trk?.[0] ?? '',
      content: item.content,
      supplier: item.supplier,
    }));
    return res;
  }
  return empty;
};

const init = {
  list: [],
  totalPages: 0,
  totalCount: 0,
};

export const askAPI = createApi({
  reducerPath: 'ask',
  endpoints: (builder) => ({
    getAskByPage: builder.query({
      queryFn: async (reqData) => {
        if (reqData?.memberId) {
          try {
            const resData = await Service.userAsk({
              ...reqData,
              supplier: 'familyMart',
            });
            if (resData?.hasError) {
              return { data: init, error: '系統忙線中，請稍後再試' };
            }
            if (resData?.ErrorMsg) {
              return { data: init, error: resData.ErrorMsg };
            }

            const formatData = {
              list: formatAsk(resData?.question_list),
              totalPages: Math.ceil(resData?.total / 10) ?? 0,
              totalCount: resData?.total ?? 0,
            };

            return { data: formatData };
          } catch (error) {
            return { data: init, error: error };
          }
        } else {
          return { data: init };
        }
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.list.push(...newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetAskByPageQuery } = askAPI;
export const resetAsk = askAPI.util.resetApiState();
