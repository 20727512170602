import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Service, suspense, checkExpiration, dayjs, constants } from 'utils';

const { token } = constants;

const getSessionCache = (platform) => {
  const listKey = `favoriteIdList_${platform}`;
  const sessionObj = sessionStorage.getItem(listKey);
  if (!sessionObj) {
    return null;
  }
  return JSON.parse(sessionObj);
};

const updateSessionCache = (platform, data) => {
  const listKey = `favoriteIdList_${platform}`;
  sessionStorage.setItem(listKey, JSON.stringify(data));
};

const deleteSessionCache = (platform) => {
  const listKey = `favoriteIdList_${platform}`;
  sessionStorage.removeItem(listKey);
};

export const favoriteCreate = createAsyncThunk(
  'favorite/favoriteCreate',
  async (reqData, { getState, rejectWithValue }) => {
    let resData = {};
    let queryStatus = {};
    let retry = 0;

    // list to update cache
    const state = getState();
    const { idList } = state.favorite;

    // 第一步驟: 將商品的reqData 透過API送回
    const fetchFavorite = async (reqData) => {
      let resData = {};
      let retry = 0;
      do {
        resData = await Service.favoriteCreate(reqData);

        if (resData.code === -5001) {
          await suspense(500);
          retry += 1;

          // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
          if (retry >= 10) break;
        }
      } while (resData.code === -5001);

      return resData;
    };

    resData = await fetchFavorite({
      ...reqData,
      languageCode: 2,
      platformKey: 'family-mart',
    });

    // 追蹤商品資料送出失敗, 或其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(`Error: ${resData.code}] ${resData.method}`);
    }

    // 第二步驟: 追蹤商品資料送出成功, 取回傳結果的queryStatus, 確定是否已寫入後台
    do {
      queryStatus = await Service.favoriteQuery(resData.data);
      if (queryStatus.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 30 次就停掉
        if (retry >= 30) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue(
        `Error: ${queryStatus.code}] ${queryStatus.method}`
      );
    }
    // 將新增結果暫存於session的快取
    updateSessionCache(reqData.channelKey, {
      ...idList,
      list: [...idList.list, reqData.productId],
    });
    return queryStatus;
  }
);

export const favoriteDelete = createAsyncThunk(
  'favorite/favoriteDelete',
  async (reqData, { getState, rejectWithValue }) => {
    let resData = {};
    let retry = 0;

    // list to update cache
    const state = getState();
    const { idList } = state.favorite;

    do {
      resData = await Service.favoriteDelete({
        ...reqData,
        platformKey: 'family-mart',
      });

      if (resData.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
        if (retry >= 10) break;
      }
    } while (resData.code === -5001);

    if (resData?.hasError) {
      return rejectWithValue('刪除收藏商品失敗');
    }

    // 從快取中移除刪除的ID, 再存回session
    const newList = idList.list.filter(
      (item) => item !== reqData.productId.toString()
    );
    updateSessionCache(reqData.channelKey, {
      ...idList,
      list: newList,
    });
    return resData;
  }
);

export const favoriteIdList = createAsyncThunk(
  'favorite/favoriteIdList',
  async (reqData, { rejectWithValue }) => {
    if (!token) {
      return initialState.idList;
    }
    const { channelKey } = reqData;

    let checkResult = false;
    const cachedValue = getSessionCache(channelKey);
    // 追蹤清單的快取為30分
    // 因為localStorage有cart等操作, 避免clear行為影響, 設定sessionStorage
    if (cachedValue) {
      checkResult = checkExpiration(cachedValue);
    }

    if (cachedValue && checkResult) {
      return cachedValue;
    }

    // 過期後刪除, 準備重取
    deleteSessionCache(channelKey);

    let resData = {};
    let retry = 0;

    do {
      resData = await Service.favoriteIdList({
        channelKey,
        platformKey: 'family-mart',
      });

      if (resData.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
        if (retry >= 10) break;
      }
    } while (resData.code === -5001);

    if (resData.hasError) {
      return { error: '取得追蹤清單ID出錯' };
    }

    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(`Error: ${code}] ${errMsg}`);
    }

    // 設定當下時間為start, 30分後為end
    const cachedList = {
      list: data.list,
      startAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      endAt: dayjs().add(30, 'minute').format('YYYY-MM-DD HH:mm:ss'),
    };

    updateSessionCache(channelKey, cachedList);

    return cachedList;
  }
);

const initialState = {
  loading: null,
  idList: {
    list: [],
    startAt: '',
    endAt: '',
  },
  error: null,
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    resetSearchTags: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // New favorite
      .addCase(favoriteCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(favoriteCreate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(favoriteCreate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Delete favorite
      .addCase(favoriteDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(favoriteDelete.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(favoriteDelete.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // favorite ids
      .addCase(favoriteIdList.pending, (state) => {
        state.loading = true;
      })
      .addCase(favoriteIdList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.idList = { ...initialState.idList, ...payload };
      })
      .addCase(favoriteIdList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default favoriteSlice.reducer;

const defaultParams = {
  products: [],
  totalCount: 0,
};

// 收藏商品清單
export const favoriteApi = createApi({
  reducerPath: 'favoriteApi',
  endpoints: (builder) => ({
    getFavoriteList: builder.query({
      queryFn: async ({ channelKey, start, end }) => {
        let resData = {};
        let retry = 0;
        do {
          resData = await Service.favoriteList({
            platformKey: 'family-mart',
            channelKey,
            start,
            end,
          });

          if (resData.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
            if (retry >= 10) break;
          }
        } while (resData.code === -5001);
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有收藏清單',
          };
        }

        const { total, list } = data;
        return {
          data: {
            products: list,
            totalCount: total,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    // builder.mutation 與同一組 builder.query 搭配
    // 這裡的deleteItem行為, 專門處理無限下拉的收藏商品清單
    // 執行時會即時更新畫面上的list data, 去確保下拉載入新一批資料時
    // 已刪除的資料不會錯位, 導致畫面出現重複項目
    deleteItem: builder.mutation({
      queryFn: async (reqData) => {
        let resData = {};
        let retry = 0;

        do {
          resData = await Service.favoriteDelete({
            ...reqData,
            platformKey: 'family-mart',
          });

          if (resData.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
            if (retry >= 10) break;
          }
        } while (resData.code === -5001);

        const { code, errMsg } = resData;
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '刪除失敗',
          };
        }
        if (code > 0) {
          return resData;
        }
      },
      // onQueryStarted: 用來更新client畫面的快取 (Optimistic Updates)
      // 當deleteItem 執行, 不會觸發 getFavoriteList 的 fetch行為
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        const deletePatch = dispatch(
          favoriteApi.util.updateQueryData('getFavoriteList', data, (draft) => {
            const index = draft.products.findIndex(
              (product) => product?.productId === data.productId
            );
            // 只移除 getFavoriteList 的列表快取資料中對應id的物件
            if (index >= 0) {
              draft.products.splice(index, 1);

              // 從快取中移除刪除的ID, 再存回session
              const cachedValue = getSessionCache(data.channelKey);

              const newList = cachedValue.list.filter(
                (item) => item !== data.productId.toString()
              );
              updateSessionCache(data.channelKey, {
                ...cachedValue,
                list: newList,
              });
            }
          })
        );
        // queryFulfilled.catch: fetch 失敗時執行undo, 維持原本的快取資料
        queryFulfilled.catch(deletePatch.undo);
      },
    }),
  }),
});

export const { useGetFavoriteListQuery, useDeleteItemMutation } = favoriteApi;
export const resetFavorite = () => favoriteApi.util.resetApiState();
