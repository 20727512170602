import { createApi } from '@reduxjs/toolkit/query/react';
import { Service } from 'utils';

let tempCategory = {};
const defaultParams = {
  products: [],
  totalPages: 0,
};

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  endpoints: (builder) => ({
    categoryByIds: builder.query({
      queryFn: async (reqData) => {
        const { page } = reqData;
        const resData = await Service.categoryQuery(reqData);
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關分類',
          };
        }

        const { totalPages, products } = data;

        // 捲動開始，須預塞第一頁資料，但捲到第二頁才寫入 localStorage
        if (page === 1) {
          tempCategory = {
            ...tempCategory,
            1: products,
          };
        } else {
          tempCategory = {
            ...tempCategory,
            [page]: products,
          };

          localStorage.setItem('pageData', JSON.stringify(tempCategory));
        }

        return {
          data: {
            products,
            totalPages,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        // 切換頁籤不做任何暫存
        if (arg.page === 1) {
          return newItems;
        }

        if (currentCache) {
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useCategoryByIdsQuery } = categoryApi;
export const resetCategory = () => categoryApi.util.resetApiState();
