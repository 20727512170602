const config = {
  common: {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: false,
  },
  search: {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: false,
  },
  'search-keyword': {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: true,
  },
  'product-id': {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: true,
  },
  category: {
    title: '商品分類',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/' },
  },
  'category-id': {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/category' },
  },
  coupon: {
    title: '我的折價券',
    cart: false,
    user: false,
    home: true,
  },
  'coupon-id': {
    title: '折價券',
    cart: false,
    user: false,
    home: true,
  },
  member: {
    title: '會員中心',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/' },
  },
  order: {
    title: '全部訂單',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/member' },
  },
  ask: {
    title: '訂單問答',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/member' },
  },
  'order-qa': {
    title: '比比昂日貨',
    cart: true,
    user: true,
    home: true,
  },
  'order-id': {
    title: '訂單明細',
    cart: false,
    user: false,
    home: true,
    state: { referrer: '/member/order' },
  },
  user: {
    title: '會員資料',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/member' },
  },
  store: {
    title: '全家門市',
    cart: true,
    user: true,
    home: true,
  },
  'store-id': {
    title: '編輯全家門市',
    cart: true,
    user: true,
    home: true,
  },
  receiver: {
    title: '收貨資訊',
    cart: true,
    user: true,
    home: true,
  },
  'receiver-id': {
    title: '編輯收貨資訊',
    cart: true,
    user: true,
    home: true,
  },
  cart: {
    title: '購物車',
    cart: false,
    user: false,
    home: true,
    heart: true,
  },
  'pay-key': {
    title: '結帳',
    cart: false,
    user: false,
    home: false,
    state: { referrer: '/cart' },
  },
  'promotion-key': {
    title: '促銷商品',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/' },
  },
  favorite: {
    title: '我的收藏',
    cart: true,
    user: true,
    home: true,
    state: { referrer: '/member' },
  },
};

export default config;
