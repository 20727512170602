import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Service,
  serviceProxy,
  formatLeadTimeByProduct,
  formatLeadTime,
} from 'utils';
import { setError } from 'reduxs/errorSlice';

const initialState = {
  loading: null,
  error: null,
  product: {},
  variation: {},
  quantities: {},
  itemIdsArray: [],
  itemId: null,

  slideProduct: {
    product: {},
    variation: {},
    quantities: {},
    itemIdsArray: [],
    loading: null,
    error: null,
  },
};

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async (id, { dispatch, rejectWithValue }) => {
    const resData = await Service.product(id);
    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(errMsg);
    }

    const {
      description,
      tagStrippedDescription,
      supplierProductDescription,
      supplierDescription,
      ...rest
    } = data;

    // 先 log 最一開始的 response，若需要加強再補 variation + quantities
    dispatch(setError(rest));

    const variation = await serviceProxy({
      url: data.otherDataUrlV2,
      except: true,
    });
    const quantities = await serviceProxy({
      url: data.availableQuantityUrl,
      except: true,
    });

    // TODO: 過渡期先用 typeof 檢查，待上線幾天後再回頭統一
    const product = {
      ...data,
      leadTime:
        typeof data.leadTime === 'string'
          ? formatLeadTimeByProduct(data.leadTime)
          : formatLeadTime(data.leadTime),
    };

    return {
      variation,
      quantities,
      product,
    };
  }
);

export const fetchSlideProduct = createAsyncThunk(
  'product/fetchSlideProduct',
  async (id, thunkAPI) => {
    try {
      const res = await Service.product(id);
      const { code, data, errMsg } = res;
      if (code && code !== 1) {
        return thunkAPI.rejectWithValue(errMsg);
      }

      const variation = await serviceProxy({
        url: data.otherDataUrlV2,
        except: true,
      });
      const quantities = await serviceProxy({
        url: data.availableQuantityUrl,
        except: true,
      });
      return {
        variation,
        quantities,
        product: {
          ...data,
          leadTime:
            typeof data.leadTime === 'string'
              ? formatLeadTimeByProduct(data.leadTime)
              : formatLeadTime(data.leadTime),
        },
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetProduct: () => initialState,
    resetSlideProduct: (state) => {
      state.slideProduct.product = initialState.slideProduct.product;
      state.slideProduct.variation = initialState.slideProduct.variation;
      state.slideProduct.quantities = initialState.slideProduct.quantities;
      state.slideProduct.itemIdsArray = initialState.slideProduct.itemIdsArray;
      state.slideProduct.loading = initialState.slideProduct.loading;
      state.slideProduct.error = initialState.slideProduct.error;
    },
    setCurrentId: (state, { payload }) => {
      state.itemId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.product = payload.product;
        state.variation = payload.variation;
        state.quantities = payload.quantities.reduce((obj, item) => {
          const [id, value] = Object.entries(item)[0];
          obj[id] = value;
          return obj;
        }, {});
        state.itemIdsArray = payload?.variation.itemData.reduce((acc, item) => {
          acc.push(item.optionIds);
          return acc;
        }, []);
      })
      .addCase(fetchProduct.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(fetchSlideProduct.pending, (state) => {
        state.slideProduct.loading = true;
      })
      .addCase(fetchSlideProduct.fulfilled, (state, { payload }) => {
        state.slideProduct.loading = false;
        state.slideProduct.product = payload.product;
        state.slideProduct.variation = payload.variation;
        state.slideProduct.quantities = payload.quantities.reduce(
          (obj, item) => {
            const [id, value] = Object.entries(item)[0];
            obj[id] = value;
            return obj;
          },
          {}
        );
        state.slideProduct.itemIdsArray = payload?.variation.itemData.reduce(
          (acc, item) => {
            acc.push(item.optionIds);
            return acc;
          },
          []
        );
      })
      .addCase(fetchSlideProduct.rejected, (state, { payload }) => {
        state.slideProduct.loading = false;
        state.slideProduct.error = payload;
      });
  },
});

export const { setCurrentId, resetProduct, resetSlideProduct } =
  productSlice.actions;

export default productSlice.reducer;
