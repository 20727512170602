import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import {
  StyledLoadingLayout,
  StyledLoadingWithMask,
  StyledContainer,
  StyledText,
  StyledLoadingSmallLayout,
  StyledLoadingScrollWrap,
} from './styled';

// 小 loading 給區塊用，需由外層給 relative
export const LoadingSmall = ({ open = false }) =>
  open && (
    <StyledLoadingSmallLayout>
      <span>
        <img src="/images/loading.gif" alt="Loading" width="24" height="24" />
      </span>
      <span>載入中</span>
    </StyledLoadingSmallLayout>
  );

LoadingSmall.propTypes = {
  open: PropTypes.bool.isRequired,
};

// 無限下拉 - 置底載入用
export const LoadingScroll = ({ loading = false }) =>
  loading && (
    <StyledLoadingScrollWrap>
      <img src="/images/loading.gif" alt="Loading" width="16" height="16" />

      <span>載入中</span>
    </StyledLoadingScrollWrap>
  );

LoadingScroll.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const Item = () => (
  <>
    <img src="/images/loading.gif" alt="Loading" width="48" height="48" />
    <StyledText>正在載入</StyledText>
  </>
);

const Loading = ({ loading = false }) => {
  if (!loading) return null;

  return createPortal(
    <StyledLoadingLayout>
      <Item />
    </StyledLoadingLayout>,
    document.querySelector('#root') // 先寫死
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loading;

export const LoadingWithMask = ({ loading = false }) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    }

    // 註銷還原
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [loading]);

  if (!loading) return null;

  return createPortal(
    <StyledLoadingWithMask
      open={loading}
      onClick={(e) => {
        // 阻擋背景行為
        e.preventDefault();
      }}
    >
      <StyledContainer>
        <Item />
      </StyledContainer>
    </StyledLoadingWithMask>,
    document.body
  );
};

LoadingWithMask.propTypes = {
  loading: PropTypes.bool,
};
