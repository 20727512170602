import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  SearchContainer,
  StyledWrapper,
  StyledInput,
  StyledButtonWrap,
  StyledButton,
} from './styled';

const SearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchKeyword } = useSelector((state) => state.common);
  const [keyword, setKeyword] = useState('');
  const [isSearchOn, setIsSearchOn] = useState(false);

  useEffect(() => {
    if (searchKeyword) {
      setKeyword(searchKeyword);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (location?.pathname === '/search') {
      setIsSearchOn(false);
    }
  }, [location.pathname]);

  const onSearchChange = (e) => {
    setKeyword(e.target.value);
  };

  // 送出後避免再輸入，造成search result頁不正確跳轉
  // 切換至gotoSearch
  const onSearchBlur = (e) => {
    e.preventDefault();
    setIsSearchOn(false);
  };

  const encodeKeyword = (keyword) => {
    let value = keyword;
    if (value === '.') {
      value = '. ';
    }
    return encodeURIComponent(value);
  };

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter' && keyword !== '') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (keyword !== '') {
      setIsSearchOn(true);
      navigate(`/search/${encodeKeyword(keyword)}`);
    }
  };

  const gotoSearch = () => {
    if (searchKeyword) {
      setIsSearchOn(false);
      navigate(`/search`);
    }
  };

  return (
    <SearchContainer onClick={gotoSearch}>
      <StyledWrapper>
        <StyledInput
          value={keyword}
          placeholder="請輸入商品關鍵字"
          onChange={onSearchChange}
          onBlur={onSearchBlur}
          onKeyPress={handleKeyEnter}
          disabled={isSearchOn}
        />
      </StyledWrapper>

      {!searchKeyword && (
        <StyledButtonWrap>
          <StyledButton
            aria-label="search-button"
            className="searchButton"
            onClick={handleSearch}
            text="搜尋"
          />
        </StyledButtonWrap>
      )}
    </SearchContainer>
  );
};

export default SearchBar;
