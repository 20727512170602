import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  Service,
  constants,
  suspense,
  decodeStorageData,
  calculate,
  dayjs,
  getCookie,
  shipmentTimeByMultiple,
  shipmentMethodByMultiple,
} from 'utils';
import { storageDataReceiverAndStore, exchangeApi } from 'reduxs/commonSlice';

const { token } = constants;
const platform = 'familyMart';
let tempReqData = {};

// TODO: 加密部分會影響主站效能，之後要換成 js 處理加密這段
// 整理送參數結構: 購物車資訊加密
const formatDataCartItemEncode = async (reqData) => {
  const productInfo = reqData.items.reduce(
    (
      acc,
      {
        productName,
        maxOrderQuantity,
        productId: productID,
        productImageUrl: img,
        productWeight,
        productItemId: specificationNo,
        productUrl,
        salePrice,
        quantity: num,
      }
    ) => {
      acc.push({
        price: salePrice,
        name: productName,
        url: productUrl,
        num,
        supplier_platform: platform,
        seller_name: reqData.cartName,
        name_lst: reqData.cartName,
        supplierKey: reqData.cartKey,
        seller: platform,
        salePrice,
        productName,
        productUrl,
        purchaseQuantity: num,
        img,
        productWeight,
        productID,
        maxOrderQuantity,
        specificationNo,
        platform,
        site: 'fmmart.bibian.co.jp',
        nation: 'japan',
      });

      return acc;
    },
    []
  );

  /**
   * 送參數
   *  - status: 固定 encodeprodinfo
   *  - platform: 頻道
   *  - ez1
   *  - productInfo: 購物車品項
   */
  const params = {
    status: 'encodeprodinfo',
    platform,
    ez1: token,
    productInfo,
  };

  return params;
};

// 整理送參數結構: 活動資格
const formatDataEventQualify = async (reqData) => {
  const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const prodInfo = reqData.items.reduce(
    (
      acc,
      {
        productName: prodname,
        productId,
        productWeight,
        productItemId,
        productUrl: url,
        salePrice,
        quantity,
      }
    ) => {
      acc.push({
        prodname,
        num: `${quantity}`,
        price: `${salePrice}`, // 取特價
        url,
        supplierKey: reqData.cartKey,
        productWeight: `${productWeight}`,
        productID: `${productId}`,
        specification_no: `${productItemId}`,
      });

      return acc;
    },
    []
  );

  /**
   * 參數
   *  - idmbr_bid: 會員編號
   *  - transit_tw_dlr: 國內運送方式
   *  - exchange_rate_bid: 匯率
   *  - won_quantity_bid: 固定 1
   *  - end_time_bid: 現在時間(YYYY-MM-DD HH:mm:ss)，例如 2024-05-07 00:00:14
   *  - order_time_bid: 現在時間(YYYY-MM-DD HH:mm:ss)，例如 2024-05-07 00:00:14
   *  - supplier: 固定 familyMart
   *  - bidorbuy_lst: 購物車品項特價總計(台幣)
   *  - buy_info_bid_arr: 購物車品項加密
   *  - supplierKey: 供應商，給主站對應好的 key，由購物車帶過來
   *  - prodInfo: 購物車品項
   *  - taiwanDeliveryFee: 國內段運費
   */
  const params = {
    idmbr_bid: reqData.uid,
    transit_tw_dlr: reqData.methods.bbnMainKey,
    exchange_rate_bid: `${reqData.exchange}`,
    won_quantity_bid: '1',
    end_time_bid: now,
    order_time_bid: now,
    supplier: platform,
    bidorbuy_lst: `${reqData.total.priceSale}`,
    buy_info_bid_arr: reqData.encodeData,
    supplierKey: reqData.items.flatMap(
      ({ bibianMainSupplierKey }) => bibianMainSupplierKey
    ),
    prodInfo,
    taiwanDeliveryFee: reqData.methods.fees.fee,
  };

  return params;
};

// TODO: 因結帳頁串主站參數有相依性，待之後換成新的行銷工具 API，應把折價券相關移到 couponSlice 統一管理
// 整理回傳欄位名稱對應
export const mappingParams = (data) =>
  data.map((obj) => {
    const {
      id,
      ticket_number: ticketNo, // 折價券代號
      web_name: name,
      title,
      type_group: groupName, // 群組名稱(單品、頻道、館別、其他)
      type_tags: channel, // 頻道
      ticket_qty: availableTimes, // 使用次數，此欄位為倒扣法，直接顯示即可
      coupon_start_at: startAt,
      expired_at: endAt,
      currency,
      limits, // 館別分類(對應商城供應商)
      qualified, // 是否符合資格
      discount_fee, // 折抵相關資訊
    } = obj;

    // 主站寫死 "BBC-XXX"，因此需先濾掉 "BBC-" 字眼再轉全小寫
    const limit = limits?.bbntw_order_supplier
      ? limits?.bbntw_order_supplier[0]?.replace('BBC-', '').toLowerCase()
      : '';

    return {
      id,
      ticketNo,
      name: name.replace(';折價券', ''), // 與主站共用結構，value 會寫死 ";折價券"，因此需先濾掉
      title,
      status: 'unuse',
      groupName,
      channel,
      availableTimes,
      startAt,
      endAt,
      currency,
      limit,
      qualified: !!qualified,
      discount: discount_fee.NT,
    };
  });

// 向主站取活動資格判定
export const fetchEventQualify = createAsyncThunk(
  'pay/fetchEventQualify',
  async (reqData, { getState, rejectWithValue, dispatch }) => {
    const { userInfo } = getState();
    const { uid } = userInfo;

    // 取得 rtk query 中的值(匯率)
    const { data: exchange } = await dispatch(
      exchangeApi.endpoints.exchange.initiate(null, { forceRefetch: true })
    );

    // 購物車品項加密
    const reqParamEncode = await formatDataCartItemEncode(reqData);
    const resDataEncode = await Service.cartItemEncode(reqParamEncode);

    // 加密 API 錯誤
    if (resDataEncode.hasError) {
      return rejectWithValue({
        type: 'cartItemEncode',
        message: '加密錯誤',
      });
    }

    // 取得活動資格
    const params = await formatDataEventQualify({
      ...reqData,
      uid,
      exchange,
      encodeData: resDataEncode.productInfo,
    });
    const resData = await Service.eventQualify(params);

    // 給其他 fn 用而佔存
    tempReqData = {
      platform: reqData.platform,
      params,
    };

    /**
     * 取活動 API 錯誤
     * Ex. API 直接掛掉或是傳結構有錯，但為了畫面正常且計算不能錯，在畫面上跳 modal 提示
     */
    if (resData.hasError) {
      return rejectWithValue({
        type: 'eventQualify',
        message: '活動資格錯誤',
      });
    }

    return resData ?? [];
  }
);

// 向主站取可使用折價券
export const fetchAvailableCoupon = createAsyncThunk(
  'pay/fetchAvailableCoupon',
  async (_, { rejectWithValue }) => {
    const resData = await Service.availableCouponList(tempReqData.params);

    /**
     * 沒有折價券情境會回傳不同資料結構，因此這邊要給預設值空陣列確保程式不會出錯
     * 可使用折價券需過濾當前頻道與全站通用(bibian)
     */
    let resList = resData.length
      ? resData.filter(
          ({ type_tags }) =>
            type_tags === 'bibian' || type_tags === tempReqData.platform
        )
      : [];

    // 其他無法預期的錯誤 (API掛掉等等)
    if (resData.hasError) {
      return rejectWithValue({
        type: 'availableCouponList',
        message: '可使用折價券錯誤',
      });
    }

    return resList;
  }
);

// 向主站取可使用全家店舖代碼
export const fetchAvailableFamilyCode = createAsyncThunk(
  'pay/fetchAvailableFamilyCode',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.availableFamilyCode({
      ...tempReqData.params,
      campaign_code: reqData,
      source:
        process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_ENV : 'www',
    });

    // 其他無法預期的錯誤 (API掛掉等等)
    if (resData.hasError) {
      return rejectWithValue({
        type: 'availableFamilyCode',
        message: '全家店舖代碼錯誤',
      });
    }

    // 客製化錯誤情境，因後端回傳結構不一致，需統一結構給畫面
    if (!resData.length) {
      return rejectWithValue(resData.type_code);
    }

    const [obj] = resData;

    return {
      code: obj.code,
      name: obj.name,
      discount: obj.discount_fee.NT,
    };
  }
);

// 取得折扣碼對應全家店舖資訊
export const fetchFamilyCodeWithStore = createAsyncThunk(
  'pay/fetchFamilyCodeWithStore',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.familyCodeWithStore(reqData);
    const { code, errMsg: message, data } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(message);
    }

    return data;
  }
);

// 付款機制
const fetchOrder = async (reqData) => {
  let resData = {};
  let retry = 0;

  do {
    resData = await Service.orderCreate(reqData);

    if (resData.code === -5001) {
      await suspense();
      retry += 1;

      // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
      if (retry >= 15) break;
    }
  } while (resData.code === -5001);

  return resData;
};

// 結帳
export const orderCreate = createAsyncThunk(
  'pay/orderCreate',
  async (reqData, { dispatch, rejectWithValue }) => {
    // 取得 rtk query 中的值(匯率)
    const { data: exchangeRate } = await dispatch(
      exchangeApi.endpoints.exchange.initiate(null, { forceRefetch: true })
    );

    let resData = {};
    let queryStatus = {};
    let retry = 0;

    // 不知為何需要前端傳 GA 給主站，目前先寫死這坨垃圾
    const domain = process.env.REACT_APP_DOMAIN;
    const gaId = getCookie('_ga_XD8XSCGH5W', { domain });
    const gaValue = getCookie('_ga', { domain });

    const tempData = {
      ...reqData,
      languageCode: 2,
      currency: 'TWD',
      exchangeRate,
      cookies: {
        ga_client: gaValue,
        ga_client_value: gaValue,
        session_id: gaId === 'deleted' ? null : gaId,
      },
    };

    resData = await fetchOrder(tempData);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue({
        type: 'order',
        code: resData.code,
        message: resData.errMsg,
        errorReqData: tempData,
        errorReqMethod: `[${resData.code}] ${resData.method} ${resData.apiUrl}`,
      });
    }

    do {
      queryStatus = await Service.orderQuery(resData.data);

      if (queryStatus.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 30 次就停掉
        if (retry >= 30) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue({
        type: 'order-query',
        code: queryStatus.code,
        message: queryStatus.errMsg,
        errorReqData: tempData,
        errorReqMethod: `[${queryStatus.code}] ${queryStatus.method} ${queryStatus.apiUrl}`,
      });
    }

    return queryStatus;
  }
);

const initialState = {
  isLoading: {
    events: false,
    coupons: false,
    order: false,
  },
  events: {
    list: [],
    total: 0,
  },
  coupons: [],
  familyCode: {},
  isErrors: {
    events: {},
    coupons: {},
    familyCode: {},
  },
  selectedCoupon: {},
};

const paySlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    resetData: () => initialState,
    resetFamilyCode: (state) => {
      state.familyCode = initialState.familyCode;
    },
    resetSelectedCoupon: (state) => {
      state.selectedCoupon = initialState.selectedCoupon;
    },
    setSelectedCoupon: (state, { payload }) => {
      state.selectedCoupon = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventQualify.pending, (state) => {
        state.isLoading.events = true;
      })
      .addCase(fetchEventQualify.fulfilled, (state, { payload }) => {
        let eventTotal = 0;
        state.isLoading.events = false;
        state.events = {
          list: payload.reduce((acc, { name, discount_fee }) => {
            acc.push({
              name,
              fee: +discount_fee.fee,
            });

            eventTotal += +discount_fee.fee;
            return acc;
          }, []),
          total: eventTotal,
        };
      })
      .addCase(fetchEventQualify.rejected, (state, { payload }) => {
        state.isLoading.events = false;
        state.errorType = payload.type;
        state.message = payload.message;
      })
      .addCase(fetchAvailableCoupon.pending, (state) => {
        state.isLoading.coupons = true;
      })
      .addCase(fetchAvailableCoupon.fulfilled, (state, { payload }) => {
        state.isLoading.coupons = false;

        // 需按照有資格放最前，不符合資格放最後呈現
        state.coupons = mappingParams(payload).sort(
          (a, b) => b.qualified - a.qualified
        );
      })
      .addCase(fetchAvailableCoupon.rejected, (state, { payload }) => {
        state.isLoading.coupons = false;
        state.errorType = payload.type;
        state.message = payload.message;
      })
      .addCase(fetchAvailableFamilyCode.fulfilled, (state, { payload }) => {
        state.familyCode.discount = payload;
      })
      .addCase(fetchAvailableFamilyCode.rejected, (state, { payload }) => {
        state.isErrors.familyCode = payload;
      })
      .addCase(fetchFamilyCodeWithStore.fulfilled, (state, { payload }) => {
        state.familyCode.store = payload;
      })
      .addCase(fetchFamilyCodeWithStore.rejected, (state, { payload }) => {
        state.isErrors.familyCode = payload;
      })
      .addCase(orderCreate.pending, (state) => {
        state.isLoading.order = true;
      })
      .addCase(orderCreate.fulfilled, (state) => {
        state.isLoading.order = false;
      })
      .addCase(orderCreate.rejected, (state, { payload }) => {
        const { type, code, message, errorReqData, errorReqMethod } = payload;
        state.isLoading.order = false;

        // TODO: 需整併到 isErrors
        state.errorType = type;
        state.code = code;
        state.message = message;
        state.errorReqData = errorReqData;
        state.errorReqMethod = errorReqMethod;
      });
  },
});

export const {
  resetData,
  resetFamilyCode,
  resetSelectedCoupon,
  setSelectedCoupon,
} = paySlice.actions;
export default paySlice.reducer;

// 從 localStorage 取出暫存資料
const storageData = () => {
  return decodeStorageData('selectedItems');
};

/**
 * 從購物車選取品項
 *  - 若 localStorage 沒有 key 時，react 環境下會是 null，因此需透過 selector 傳遞給畫面
 *  - 含國際運費，但不含國內運費(國內由另一個 selector 處理)
 */
export const selectedItemsSelector = createSelector(storageData, (data) => {
  if (!Object.keys(data).length) return data;

  /**
   * 對其他欄位做整理
   *  - 備貨期(leadTime)
   *  - 配送方式(specialTagId): 複合式以最糟的條件為準(烏龜、火箭、一般)
   *  - 車子品項加總(cartSubtotal): 以原匯率計算(含單價*數量後再加總)
   *    > 原價(listPrice)
   *    > 特價(salePrice)
   */
  const tempData = {
    ...data,
    leadTime: shipmentTimeByMultiple(data.items),
    shipment: shipmentMethodByMultiple(data.items),
    cartSubtotal: data.items.reduce(
      (acc, { listPrice, salePrice, quantity }) => {
        acc = {
          listPrice: calculate(acc.listPrice + listPrice * quantity),
          salePrice: calculate(acc.salePrice + salePrice * quantity),
        };
        return acc;
      },
      {
        listPrice: 0,
        salePrice: 0,
      }
    ),
  };

  return tempData;
});

export const selectedReceiverAndStoreSelector = createSelector(
  storageDataReceiverAndStore,
  (state) => state.stores.list,
  (state) => state.receiver.list,
  (selected, store, receiver) => {
    const tempReceiver = receiver.find(({ preference }) => preference) ?? {};
    const tempStore = store.find(({ preference }) => preference) ?? {};
    const tempData = {
      receiver: Object.keys(selected.receiver).length
        ? selected.receiver
        : tempReceiver,
      store: Object.keys(selected.store).length ? selected.store : tempStore,
    };

    return tempData;
  }
);

export const supplierCheckSelector = createSelector(storageData, (data) => {
  /**
   * 供應商檢查:
   * - 確認商品中是否有不屬於比比昂嚴選(bibian)的商品
   * - 回傳值為false時: 不顯示頁面上方"最快5天到貨"的提示
   */
  if (!Object.keys(data).length) return false;

  return data.items.every((item) => item.supplierKey === 'bibian');
});
