/**
 * 編碼
 * @param {object} data - 物件或陣列
 * @returns {string}
 */
export const encodeStorageData = (keyName, data) => {
  const temp = btoa(encodeURIComponent(JSON.stringify(data)));
  localStorage.setItem(keyName, temp);
};

/**
 * 解碼
 * @param {string} data - 被編碼過的一串文字資料
 * @returns {object}
 */
export const decodeStorageData = (keyName) => {
  const storage = localStorage.getItem(keyName);

  if (!storage) return {};

  const decode = atob(storage);
  const temp = JSON.parse(decodeURIComponent(decode));
  return temp;
};
