import tw, { styled } from 'twin.macro';
import Button from 'components/button';

export const StyledErrorLayout = styled.section`
  ${tw`
    h-full
    flex
    items-center
    justify-center
    p-4
  `}
`;

export const StyledWrap = styled.div`
  ${tw`
    max-w-[320px]
    w-full
    flex
    flex-col
    gap-6
    items-center
  `}
`;

export const StyledActions = styled.div`
  ${tw`w-full flex gap-4`}
`;

export const StyledButton = styled(Button)`
  ${tw`flex-1 px-4 py-2.5`}
`;
