import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  productId: null,
  itemQty: 1,
  itemId: null,
};

const variationSlice = createSlice({
  name: 'variation',
  initialState,
  reducers: {
    resetVariation: (state) => {
      state.itemQty = initialState.itemQty;
      state.itemId = initialState.itemId;
      state.productId = initialState.productId;
    },
    setItemQty: (state, { payload }) => {
      state.itemQty = payload;
    },
    setItemId: (state, { payload }) => {
      state.itemId = payload;
    },
    setProductId: (state, { payload }) => {
      state.productId = payload;
    },
  },
});

export const { resetVariation, setItemQty, setItemId, setProductId } = variationSlice.actions;
export default variationSlice.reducer;
