import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { fetchFamilyInfo } from 'reduxs/familyInfoSlice';

import { useAuth } from 'hooks';
import Empty from 'components/empty';

const transInfo = Cookies.get('Trans_info2');

const Protected = () => {
  const { redirectUrl, message } = useSelector((state) => state.familyInfo);
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const refetch = async () => {
    try {
      await dispatch(fetchFamilyInfo()).unwrap();
    } catch (error) {
      console.log('refetch FamilyInfo error?', error);
    }
  };

  if (!isAuthenticated) {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
    if (!redirectUrl && message) {
      return <Empty buttonText="返回首頁" />;
    }
    if (transInfo && !message) {
      // ez1失效 & transInfo存在 & 沒有錯誤訊息(barcode無效等), 重取
      refetch();
    }
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};

export default Protected;
