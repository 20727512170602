import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { Service, dayjs } from 'utils';

// 整理原始資料的資料結構
const arrangeOriginData = (data) =>
  data.reduce((acc, curr) => {
    /**
     * 欄位說明
     *  - id: 串訂單需要此欄位(Ex. 宅配、全家超商取貨)
     *  - key: 因 id 會變需要 key 判斷畫面的呈現(Ex. 宅配、全家超商取貨)
     *  - name: 2 為中文，因全家沒有多語系故寫死
     *  - rules: 原後端 API 的材積資訊(長/寬/高/重量/費用)、已包含活動過濾
     *    > channelKey: 頻道 key
     *      >> long: 長(公分)
     *      >> width: 寬(公分)
     *      >> height: 高(公分)
     *      >> weight: 重量(公克)，但購物車已先轉成公斤，計算時需注意
     *      >> fee: 費用(台幣)
     */
    acc.push({
      id: curr.id,
      key: curr.methodKey,
      bbnMainKey: curr.bbnMainMethodKey,
      name: curr.names['2'],
      rules: curr.rules.reduce((accObj, currObj) => {
        let eventRules = {};
        const key = currObj.channelKey ? currObj.channelKey : 'all';

        // 過濾活動區間: 有可能會有過期的活動尚未更新快取包，因此需要先做一次過濾
        if (currObj.events.length) {
          eventRules = currObj.events.find(({ startAt, endAt }) =>
            dayjs().isBetween(startAt, endAt)
          );
        }

        // 判斷是否有活動
        const config = currObj.events.length
          ? eventRules
            ? eventRules.rules
            : currObj.rules
          : currObj.rules;

        // 用 channelKey 去對應
        accObj[key] = config;

        return accObj;
      }, {}),
    });

    return acc;
  }, []);

// 整理頻道排除(rules)
const arrangeRuleByChannel = (data, channel) =>
  data.map(({ id, key, bbnMainKey, name, rules }) => {
    // 若後台沒設定頻道費用表就吃預設(因後端會回傳空的 channelKey，已在 marketSlice 給預設值 key 叫 all)
    const config = rules[channel] ? rules[channel] : rules.all;
    const tempData = {
      id,
      key,
      bbnMainKey,
      name,
      fees: config,
    };

    return tempData;
  });

// 運費表(國際 + 國內)
export const fetchShippingFee = createAsyncThunk(
  'market/fetchShippingFee',
  async (_, { rejectWithValue }) => {
    try {
      // 全家固定為台灣(TWN)
      const resData = await Service.shippingFee();
      return resData['TWN'];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const marketSlice = createSlice({
  name: 'market',
  initialState: {
    domestic: [],
    international: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingFee.fulfilled, (state, { payload }) => {
        const { domestic, international } = payload;
        state.domestic = arrangeOriginData(domestic.methods);
        state.international = arrangeOriginData(international.methods);
      })
      .addCase(fetchShippingFee.rejected, (state, { payload }) => {
        state.message = payload;
      });
  },
});

export default marketSlice.reducer;

// 國際與國內運費表
export const feeSelector = createSelector(
  (state) => state.market,
  (_, data) => data,
  (data, params) => {
    const { international, domestic } = data;
    const tempData = {
      international: arrangeRuleByChannel(international, params?.channel ?? ''),
      domestic: arrangeRuleByChannel(domestic, params?.channel ?? ''),
    };
    return tempData;
  }
);

// 國內運費計算
export const domesticFeeSelector = createSelector(
  (_, data) => data,
  ({ list, price }) => {
    const tempData = list.map((obj) => {
      const tempObj = {
        ...obj,
        fees: obj.fees.find(({ minAmount }) => price >= minAmount),
      };
      return tempObj;
    });
    return tempData;
  }
);
