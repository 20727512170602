import tw, { styled } from 'twin.macro';
import Button from 'components/button';

export const SearchContainer = styled.div`
  ${tw`
    flex
    my-1.5
    place-items-center
    bg-white
    rounded-full
    w-full
    border
    border-grayScale-400
    min-h-[2rem]
  `}
`;

export const StyledWrapper = styled.div`
  ${tw`max-h-5 h-full px-3`}
`;

export const StyledInput = styled.input`
  ${tw`text-sm outline-none border-none bg-transparent py-0 focus:outline-none pointer-events-auto`}
  ${({ disabled }) => disabled && tw`pointer-events-none`}
`;

export const StyledButtonWrap = styled.div`
  ${tw`my-0.5 mr-1`}
`;

export const StyledButton = styled(Button)`
  ${tw`text-xs font-normal px-3 py-1 whitespace-nowrap`}
`;
