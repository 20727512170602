import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logData: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.logData = payload;
    },
  },
});

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;
