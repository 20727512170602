import React from 'react';
import PropTypes from 'prop-types';
import { StyledLinksLayout } from './styled';

const Links = ({
  to,
  text,
  children,
  target = '',
  enableUnderline = false,
  ...rest
}) => {
  const urlMatch = (url) => {
    const selfRoute = new RegExp(/(^\/.*)/gm);
    const fullRoute = new RegExp(/(http.*)/gm);
    const bibianRoute = new RegExp(/(.bibian.co.jp)/gm);

    if (selfRoute.exec(url)?.length || bibianRoute.exec(url)?.length) {
      return false;
    }
    if (fullRoute.exec(url)?.length) {
      return true;
    }
    return false;
  };

  // 非比比昂網域下的網址和外連網址，一律另開分頁(_blank)
  const newTab = urlMatch(to);
  // 有傳target時，以target的屬性為最優先
  let method = newTab ? '_blank' : '_self';
  if (target) {
    method = target;
  }

  return (
    <StyledLinksLayout
      to={to}
      target={method}
      enableunderline={`${enableUnderline}`} // 屬性必須是全小寫
      {...rest}
    >
      {children ? children : text}
    </StyledLinksLayout>
  );
};

Links.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.any,
  target: PropTypes.any,
  enableUnderline: PropTypes.bool,
};

export default Links;
