/**
 * 過濾國際運費表的第一免運門檻
 * @param {array} data - 要過濾的資料包
 * @returns {object}
 */
export const filterInternationalFirstFree = (data) =>
  data.find(({ fee }) => fee === 0);

/**
 * 過濾國內運費表的 key
 * @param {array} data - 要過濾的資料包
 * @param {number} id - 當前 id
 * @returns {object}
 */
export const filterDomesticById = (data, id) =>
  data.find((obj) => obj.id === id);
