import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setPageTitle } from 'reduxs/commonSlice';
import { qaStatusSelector } from 'reduxs/orderSlice';
import { favoriteIdList } from 'reduxs/favoriteSlice';

import config from './config';
import { constants } from 'utils';
import SearchBar from 'components/searchBar';
import {
  StyledHeader,
  StyledContainer,
  StyledWrap,
  StyledLeftWrap,
  StyledTitle,
  StyledRightWrap,
  StyledIconBox,
  StyledLinks,
  StyledBadge,
  StyledMenuIcon,
  StyledSearchIcon,
  StyledCartIcon,
  StyledLeftArrowIcon,
  StyledBibianUserIcon,
  StyledHomeColorIcon,
  StyledHeartIcon,
} from './styled';

const Header = () => {
  const { cartCount, pageTitle } = useSelector((state) => state.common);
  const qaStatus = useSelector(qaStatusSelector);
  const { pageTitle: title, token, couponUrl } = constants;

  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [type, setType] = useState('common');
  const [headerTitle, setHeaderTitle] = useState('');

  const paths = pathname.split('/').filter((item) => item) ?? [];
  const query = Object.keys(params)?.[0];
  let [searchParams] = useSearchParams();
  const platform = searchParams.get('platform') ?? 'drug';

  useEffect(() => {
    if (paths.length > 0) {
      const authRoute = paths.includes('member') || paths.includes('store');
      const qa = paths.includes('qa');
      const category = paths.includes('category');
      const route =
        authRoute && paths.length > 1
          ? pathname.split('/')?.[2]
          : pathname.split('/')?.[1];
      const routeType = qa
        ? `${route}-qa`
        : query
        ? `${route}-${query}`
        : route;
      const routeTitle = qa
        ? `訂單${params[query]}`
        : config[routeType]?.title ?? title;

      setType(routeType);
      if (category && query) {
        return;
      }
      dispatch(setPageTitle(routeTitle));
    } else {
      setType('common');
      dispatch(setPageTitle(title));
    }
  }, [paths, query]);

  useEffect(() => {
    // 登入時, 重取收藏
    if ((token && pathname) || (token && platform)) {
      dispatch(favoriteIdList({ channelKey: platform ?? 'drug' }));
    }
  }, [pathname, token, platform]);

  useEffect(() => {
    document.title = pageTitle;
    setHeaderTitle(pageTitle);
  }, [pageTitle]);

  const prevNavigate = () => {
    if (state?.referrer) {
      navigate(state.referrer);
      return;
    }
    if (config[type]?.state?.referrer) {
      navigate(config[type].state.referrer);
      return;
    } else {
      navigate(-1);
      return;
    }
  };

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledLeftWrap type={type}>
          {type !== 'common' ? (
            <>
              <StyledIconBox onClick={prevNavigate}>
                <StyledLeftArrowIcon />
              </StyledIconBox>

              {config[type]?.home && (
                <StyledLinks to="/">
                  <StyledHomeColorIcon />
                </StyledLinks>
              )}
            </>
          ) : (
            <>
              <StyledLinks to="/category">
                <StyledMenuIcon />
              </StyledLinks>
              <StyledLinks to="/search">
                <StyledSearchIcon />
              </StyledLinks>
            </>
          )}
        </StyledLeftWrap>

        <StyledWrap>
          {type.includes('search') ? (
            <SearchBar />
          ) : (
            <StyledTitle type={type}>{headerTitle}</StyledTitle>
          )}
        </StyledWrap>

        <StyledRightWrap type={type} isLogged={!!token}>
          {token && config[type]?.cart && (
            <StyledLinks to="/cart">
              <StyledBadge text={cartCount > 99 ? '99+' : `${cartCount}`} />
              <StyledCartIcon />
            </StyledLinks>
          )}

          {config[type]?.user && (
            <StyledLinks to="/member">
              {!token ? '登入' : <StyledBibianUserIcon />}
            </StyledLinks>
          )}

          {config[type]?.heart && (
            <StyledLinks to="/member/favorite">
              <StyledHeartIcon />
            </StyledLinks>
          )}

          {type === 'order-id' && (
            <StyledLinks
              to={`/member/order/${params.id}/qa`}
              text="訂單問答"
              status={`${qaStatus}`}
            />
          )}

          {type === 'coupon' && <StyledLinks to={couponUrl} text="使用說明" />}
        </StyledRightWrap>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
