import tw, { styled } from 'twin.macro';
import Button from 'components/button';

export const StyledEmptyLayout = styled.div`
  ${tw`flex h-[80vh]`}
`;

export const StyledEmptyContainer = styled.div`
  ${tw`w-full h-fit my-auto bg-white
    inline-flex
    flex-col
    // justify-center
    // items-center
    text-center
    px-3
    py-10
    gap-10
  `}
`;
export const StyledContentWrap = styled.div`
  ${tw`flex flex-col justify-center items-center gap-4`}
`;

export const StyledTextWrap = styled.div`
  ${tw`gap-1 [word-break: break-word]`}
`;

export const StyledTitle = styled.p`
  ${tw`text-grayScale-900 text-lg`}
`;

export const StyledContent = styled.div`
  ${tw`text-grayScale-700 text-sm`}
`;

export const StyledButton = styled(Button)`
  ${tw`h-10`}
`;
