import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserId } from 'reduxs/userInfoSlice';
import Loading from 'components/loading';

const BaseCoupon = () => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (!uid) {
      dispatch(fetchUserId());
    }
  }, [uid]);

  if (!uid) {
    return <Loading loading={true} />;
  }

  return <Outlet />;
};

export default BaseCoupon;
