import CryptoJS from 'crypto-js';

/**
 *
 * @param {*} encrypted 加密字串
 * @returns 解密字串
 */
export const decryptFunction = (encrypted) => {
  const key = CryptoJS.enc.Base64.parse(
    process.env.REACT_APP_CRYPTO_SECRET_EXT
  );
  // 解析加密字符串為JSON對象
  const encryptedJson = JSON.parse(atob(encrypted));

  // 使用相同的密鑰和IV進行解密
  const decrypted = CryptoJS.AES.decrypt(encryptedJson.value, key, {
    iv: CryptoJS.enc.Base64.parse(encryptedJson.iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // 將解密後的數據轉換為UTF-8格式的字符串
  const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

  // 解密字串為PHP格式 ex: 's:ff:"Name"'
  let matchString = decryptedString.match(/"(.*)"/gm);
  if (matchString && matchString.length) {
    return matchString[0].replaceAll('"', '');
  }

  // 解密字串為PHP格式 ex: 'i:ff'
  const regexInt = /i:(\d+);/gm;
  let matchInt = regexInt.test(decryptedString);
  if (matchInt) {
    const result = decryptedString.replace(regexInt, '$1');
    return +result;
  }

  return decryptedString;
};

/**
 *
 * @param {*} string 原始字串
 * @returns MD5 單向加密字串
 */
export const encryptMD5 = (string) => {
  return CryptoJS.MD5(string).toString();
};

/**
 *
 * @param {*} string 原始字串
 * @returns BASE64 單向加密字串
 */
export const encryptBASE64 = (string) => {
  const word = CryptoJS.enc.Utf8.parse(string);
  return CryptoJS.enc.Base64.stringify(word);
};
