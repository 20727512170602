import Cookies from 'js-cookie';
import { dayjs } from 'utils';

export const setBasicExpire = (minute = 1440) => {
  // default: 60 * 24 minutes
  return dayjs().add(minute, 'minute').toDate();
};

export const setCookieWithExpires = (name, value, minutes = '') => {
  let expiryTime = setBasicExpire();
  if (minutes) {
    expiryTime = setBasicExpire(minutes);
  }
  Cookies.set(name, value, { expires: expiryTime });
};

export const setCookieWithDomain = (name, value, minutes = '', domain) => {
  let expiryTime = setBasicExpire();
  if (minutes) {
    expiryTime = setBasicExpire(minutes);
  }
  Cookies.set(name, value, {
    path: '/',
    domain: domain || process.env.REACT_APP_DOMAIN,
    expires: expiryTime,
  });
};

export const removeCookie = (name) => {
  Cookies.remove(name, {
    path: '/',
    domain: process.env.REACT_APP_DOMAIN,
  });
};

// TODO: 尚未全部實驗完成，先保留這些寫法
// 有效期限預設 10 分鐘
export const setCookie = (name, value, minutes = 10) => {
  const expiryTime = dayjs().add(minutes, 'minute').toDate();
  const options = {
    path: '/',
    domain: process.env.REACT_APP_DOMAIN,
    expires: expiryTime,
  };

  Cookies.set(name, value, options);
  Cookies.set(`${name}_expires`, expiryTime.toISOString(), options);
};

export const getCookie = (name, options) => {
  return Cookies.get(name, options);
};

export const getCookieExpiry = (name) => {
  const expiryString = getCookie(`${name}_expires`);
  return expiryString ? dayjs(expiryString) : null;
};
