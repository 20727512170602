import { constants } from 'utils';

export const useAuth = () => {
  const { token } = constants;

  let isAuthenticated = false;
  if (token) {
    isAuthenticated = true;
  }

  return { isAuthenticated };
};
