import React from 'react';
import PropTypes from 'prop-types';
import { onImageError, constants } from 'utils';

const { defaultImage } = constants;

const Image = ({ src, ...rest }) => (
  <img
    src={src || defaultImage}
    loading="lazy"
    onError={onImageError}
    {...rest}
  />
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;
