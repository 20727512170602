import 'dayjs/locale/zh-tw';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

dayjs.locale('zh-tw');
dayjs.extend(isBetween);
// dayjs.extend(utc);
// dayjs.extend(timezone);

export { dayjs };
