import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service } from 'utils';

const initialState = {
  loading: null,
  error: null,
  adUrl: '',
  productUrl: '',
};

export const fetchHome = createAsyncThunk('home/fetchHome', async (_, thunkAPI) => {
  try {
    const res = await Service.home();
  
    const { positions, promotions } = res.homePage;
    return {
      adUrl: positions,
      productUrl: promotions,
    };

  } catch (err) {
    thunkAPI.rejectWithValue('Home error')
  }
});
const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    resetHome: (state) => {
      state.loading = initialState.loading;
      state.adUrl = initialState.adUrl;
      state.productUrl = initialState.productUrl;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHome.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.adUrl = payload.adUrl;
        state.productUrl = payload.productUrl;
      })
      .addCase(fetchHome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetHome } = homeSlice.actions;

export default homeSlice.reducer;
