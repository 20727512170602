/**
 * @param   {number} num - 計算試(請參考範例)
 * @returns {number} 整數或小數兩位
 * @example
 * calculate(0.1+0.2) -> 0.3
 * calculate(0.7-0.6) -> 0.1
 * calculate(0.1*0.2) -> 0.02
 * calculate(0.1/0.3) -> 0.333333
 */
export const calculate = (num) => {
  return parseFloat(num.toPrecision(12));
};
